import React from "react";
import Header from "../components/header";
import Layout from "../components/layout";
import ErrorImg from "../pages/images/404.png";
import WithProviders from "../components/WithProviders";

const ErrorComponent = (props) => {
  const { language, updateLanguage, direction, location } = props;
  return (
    <Layout language={language} location={location} direction={direction}>
      <div>
        <Header
          language={language}
          updateLanguage={updateLanguage}
          direction={direction}
        />
        <div className="error-404">
          <img src={ErrorImg} alt="Error 404" loading="lazy" />
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(ErrorComponent);
